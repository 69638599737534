<template>
    <vx-card v-if="renderForm" :title="this.title" class="po-card">
        <div class="mb-6 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>
        <div v-if="(id)" class="mb-6 vx-row">
            <div class="flex items-center w-full vx-col sm:w-1/6">
                <span>PO Number</span>
            </div>
            <div class="w-full vx-col sm:w-5/6">
                <input :value="data.po_number" :readonly="true" disabled class="rounded w-full py-2 px-3 bg-grey-light" style="border: 1px solid #e8e8e8;"/>
            </div>
        </div>
        <div v-if="(id)" class="mb-6 vx-row">
            <div class="flex items-center w-full vx-col sm:w-1/6">
                <span>Created By</span>
            </div>
            <div class="w-full vx-col sm:w-5/6">
                <input :value="createdBy" :readonly="true" disabled class="rounded w-full py-2 px-3 bg-grey-light" style="border: 1px solid #e8e8e8;"/>
            </div>
        </div>
        <div v-if="(data.external_code)" class="mb-6 vx-row">
            <div class="flex items-center w-full vx-col sm:w-1/6">
                <span>External Code</span>
            </div>
            <div class="w-full vx-col sm:w-5/6">
                <input :value="data.external_code" :readonly="true" disabled class="rounded w-full py-2 px-3 bg-grey-light" style="border: 1px solid #e8e8e8;"/>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="flex items-center w-full vx-col sm:w-1/6">
                <span>Purchase Group</span>
            </div>
            <div class="w-full vx-col sm:w-5/6">
                <CustomMSelect :disabled="isB2B || this.isDetail" :value="data.purchaseGroup" base-url="/api/wms/v1/wms/purchase-group"
                    label="name" track-by="name" @selected="(v) => {
                    data.purchaseGroup = v;
                    this.renderPurchaseType = false;
                    this.$nextTick(() => this.renderPurchaseType = true);
                }"></CustomMSelect>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="flex items-center w-full vx-col sm:w-1/6">
                <span>Purchase Type</span>
            </div>
            <div class="w-full vx-col sm:w-5/6" v-if="renderPurchaseType">
                <CustomMSelect :disabled="isB2B || this.isDetail" :value="data.purchaseType"
                    :base-url="`/api/wms/v1/wms/purchase-type?group_id=${data.purchaseGroup ? data.purchaseGroup.id : 0}`"
                    label="name" track-by="name" @selected="(v) => {
                    data.purchaseType = v;
                    data.warehouses.forEach((w, i) => {
                        data.warehouses[i].renderItems = false;
                        $nextTick(() => data.warehouses[i].renderItems = true);
                    })
                    if (v.name == 'Back2Back') {
                        isB2B = true;
                        refreshForm();
                    }
                }"></CustomMSelect>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="flex items-center w-full vx-col sm:w-1/6">
                <span>Supplier Name</span>
            </div>
            <div class="w-full vx-col sm:w-5/6">
                <CustomMSelect :value="data.supplier" base-url="/api/wms/v1/master/suppliers" label="codename"
                    :disabled="isB2B || this.isDetail"
                    track-by="codename" @selected="(v) => {
                    data.supplier = v;
                    data.paymentTerms=v.payment_term
                    refreshLineData()
                    if (!isB2B) {
                        data.warehouses.forEach((w, i) => {
                            data.warehouses[i].renderItems = false;
                            $nextTick(() => data.warehouses[i].renderItems = true);
                        })
                    }
                }"></CustomMSelect>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="flex items-center w-full vx-col sm:w-1/6">
                <span>Payment Terms</span>
            </div>
            <div class="w-full vx-col sm:w-5/6" style="font-weight: 600;">
                <CustomMSelect :disabled="isB2B||(!draft)||this.isDetail" :value="data.paymentTerms" base-url="/api/wms/v1/master/payment-terms"
                    label="name" track-by="name" @selected="(v) => {
                    data.paymentTerms = v;
                }"></CustomMSelect>
            </div>
        </div>
        <div v-for="(warehouse, indexwh) in data.warehouses" :key="indexwh" class="warehouses">
            <div class="mb-6 vx-row">
                <div class="flex items-center w-full vx-col sm:w-1/6">
                    <span>Dest. Warehouse</span>
                </div>
                <div class="w-full vx-col sm:w-5/6">
                    <CustomMSelect :disabled="isB2B||cisDetail" :value="data.warehouses[indexwh].warehouse"
                        base-url="/api/wms/v1/master/warehouses" label="name" track-by="name" @selected="(v) => {
                        data.warehouses[indexwh].warehouse = v;
                        data.warehouses[indexwh].renderItems = false;
                        $nextTick(() => data.warehouses[indexwh].renderItems = true);
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row">
                <div class="flex items-center w-full vx-col sm:w-1/6">
                    <span>Date</span>
                </div>
                <div class="w-full vx-col sm:w-5/6">
                    <datepicker :disabled-dates="disabledDates" :disabled="isB2B||cisDetail" name="date" :inline="false"
                        v-model="data.date" format="yyyy-MM-dd" placeholder="Select Date" :cleared="() => {
                        this.data.date = null
                    }">
                    </datepicker>
                </div>
            </div>
            <div class="mb-6 vx-row">
                <div class="flex items-center w-full vx-col sm:w-1/6">
                    <span>Estimate to Arrive</span>
                </div>
                <div class="w-full vx-col sm:w-5/6">
                    <datepicker :disabled-dates="disabledDates" :disabled="isB2B||cisDetail" name="date" :inline="false"
                        v-model="data.eta" format="yyyy-MM-dd" placeholder="Select Date" :cleared="() => {
                        this.data.eta = null
                    }">
                    </datepicker>
                </div>
            </div>
            <div class="mb-6 vx-row">
                <div class="flex items-center w-full vx-col sm:w-1/6">
                    <span>Currency</span>
                </div>
                <div class="w-full vx-col sm:w-5/6">
                    <CustomMSelect :disabled="isB2B||cisDetail" :value="data.currency" base-url="/api/wms/v1/master/currencies"
                        label="code" track-by="code" @selected="(v) => {
                        data.warehouses[indexwh].currency = v;
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row">
                <div class="flex w-full vx-col sm:w-1/6">
                    <span>Note</span>
                </div>
                <div class="w-full vx-col sm:w-5/6">
                    <vs-textarea v-model="data.warehouses[indexwh].notes" :readonly="cisDetail" width="100%" />
                </div>
            </div>
            <div v-if="data.warehouses[indexwh].history_notes !=null" class="mb-6 vx-row">
                <vs-divider>History Notes</vs-divider>
                <vs-table :data="data.warehouses[indexwh].history_notes" width="100%" class="vs-table vs-table--tbody-table">                        
                    <template slot="thead">
                        <vs-th width="30%">Time </vs-th>
                        <vs-th width="30%">User </vs-th>
                        <vs-th width="40%">Note</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr v-for="(note, index) in data" :key="index" >
                            <vs-td>{{ note.created_at }}</vs-td>
                            <vs-td>{{ note.personal_name }}</vs-td>
                            <vs-td>
                                <vs-textarea class="custom-textarea" :value="note.notes" readonly="true" />
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
                <vs-divider/>
            </div>
            <div class="mb-6 vx-row">
                <div class="flex w-full vx-col sm:w-1/12">
                    <span>Charges</span>
                </div>
                <div class="w-full vx-col sm:w-11/12">
                    <table width="100%" class="vs-table vs-table--tbody-table">
                        <thead class="vs-table--thead">
                            <tr>
                                <th width="20%">Name</th>
                                <th width="20%">Amount</th>
                                <th width="15%">Tax</th>
                                <th width="20%">Tax Amount</th>
                                <th width="20%">Subtotal</th>
                                <th width="5%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(tax, index) in data.warehouses[indexwh].charge.taxes" :key="index"
                                style="padding-bottom: 5px">
                                <td class="td vs-table--td" style="padding: 5px">
                                    <CustomMSelect :disabled="isB2B||cisDetail"
                                        :value="data.warehouses[indexwh].charge.taxes[index].name"
                                        :key="data.warehouses[indexwh].charge.taxes[index].name"
                                        base-url="/api/wms/v1/master/charges" label="name" track-by="name" @selected="(v) => {
                                        data.warehouses[indexwh].charge.taxes[index].name = v;
                                        if (v.default_tax) {
                                            data.warehouses[indexwh].charge.taxes[index].tax = v.default_tax;
                                        } else {
                                            data.warehouses[indexwh].charge.taxes[index].tax = null;
                                        }

                                        data.warehouses[indexwh].charge.taxes[index].renderTax = false
                                        $nextTick(() => {
                                            data.warehouses[indexwh].charge.taxes[index].renderTax = true
                                        })
                                        calculateCharges();
                                    }"></CustomMSelect>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input type="text" min="0" :disabled="isB2B||cisDetail"
                                        v-model="data.warehouses[indexwh].charge.taxes[index].amount"
                                        @input="(v) => { calculateCharges(); formatNumber(indexwh, index, 'charges', 'amount')}" class="w-full" />
                                </td>
                                <td v-if="data.warehouses[indexwh].charge.taxes[index].renderTax"
                                    class="td vs-table--td" style="padding: 5px">
                                    <CustomMSelect :disabled="isB2B||cisDetail"
                                        :value="data.warehouses[indexwh].charge.taxes[index].tax"
                                        :key="data.warehouses[indexwh].charge.taxes[index].tax"
                                        base-url="/api/wms/v1/master/taxes" label="name" track-by="name"
                                        @selected="(v) => { data.warehouses[indexwh].charge.taxes[index].tax = v; calculateCharges(); }">
                                    </CustomMSelect>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input disabled readonly
                                        :value="data.warehouses[indexwh].charge.taxes[index].tax_amount"
                                        class="w-full bg-grey-light" />
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input v-model="data.warehouses[indexwh].charge.taxes[index].subtotal" disabled
                                        readonly class="w-full bg-grey-light" />
                                </td>
                                <td class="td vs-table--td" style="text-align: center">
                                    <div v-if="!isB2B && !cisDetail" class="flex vx-input-group">
                                        <vs-button v-if="data.warehouses[indexwh].charge.taxes.length > 1"
                                            @click.stop="removeTaxRow(indexwh, index)" size="small" color="danger"
                                            icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                            title="Remove Row" />
                                        <vs-button v-if="index == data.warehouses[indexwh].charge.taxes.length - 1"
                                            @click.stop="addTaxRow(indexwh)" size="small" color="success"
                                            icon-pack="feather" icon="icon-plus" title="Add Row" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <th>Total Charge</th>
                                <td><vs-input disabled readonly :value="formattedNumber(data.warehouses[indexwh].charge.total_charges)"
                                        class="w-full bg-grey-light" />
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <th>Total Tax</th>
                                <td><vs-input disabled readonly :value="formattedNumber(data.warehouses[indexwh].charge.total_taxes)"
                                        class="w-full bg-grey-light" /></td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <th>Grand Total</th>
                                <td><vs-input disabled readonly :value="formattedNumber(data.warehouses[indexwh].charge.grand_total)"
                                        class="w-full bg-grey-light" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="mb-6 vx-row">
                <div class="flex w-full vx-col sm:w-1/12">
                    <span>Items</span>
                </div>
                <div class="w-full vx-col sm:w-11/12">
                    <table width="100%" class="vs-table vs-table--tbody-table">
                        <thead class="vs-table--thead">
                            <tr>
                                <th width="8%">Sku Code</th>
                                <th width="15%">Item Name</th>
                                <th width="8%">Units</th>
                                <th width="6%">Qty</th>
                                <th width="11%">Price</th>
                                <th width="10%">Subtotal</th>
                                <th width="11%">Discount</th>
                                <th width="10%">Net</th>
                                <th width="8%">Tax</th>
                                <th width="10%">Total</th>
                                <th width="5%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in data.warehouses[indexwh].cart.items" :key="index"
                                style="padding-bottom: 5px; font-weight: 600;">
                                <td v-if="data.warehouses[indexwh].renderItems" class="td vs-table--td"
                                    style="padding: 5px">
                                    <CustomMSelect :disabled="isB2B||cisDetail"
                                        :value="data.warehouses[indexwh].cart.items[index].name"
                                        :base-url="`/api/wms/v1/master/items/supplier/${data.supplier ? data.supplier.id : 0}?type=${data.purchaseType ? data.purchaseType.id : 0}`"
                                        label="sku_code" track-by="sku_code" @selected="(v) => {
                                        data.warehouses[indexwh].cart.items[index].name = v
                                        data.warehouses[indexwh].cart.items[index].unit = null
                                        data.warehouses[indexwh].cart.items[index].tax = null
                                        if (bp&&tickCount>1) {
                                            data.warehouses[indexwh].cart.items[index].price = 0
                                            data.warehouses[indexwh].cart.items[index].discount = 0
                                        }
                                        tickCount++
                                        data.warehouses[indexwh].cart.items[index].renderUnits = false
                                        data.warehouses[indexwh].cart.items[index].renderTaxes = false
                                        $nextTick(() => {
                                            data.warehouses[indexwh].cart.items[index].renderTaxes = true
                                            data.warehouses[indexwh].cart.items[index].renderUnits = true
                                        })
                                        calculateItems()
                                    }"></CustomMSelect>
                                </td>
                                <td v-if="data.warehouses[indexwh].renderItems" class="td vs-table--td"
                                    style="padding: 5px; font-weight: 600;">
                                    <CustomMSelect :disabled="isB2B||cisDetail"
                                        :value="data.warehouses[indexwh].cart.items[index].name"
                                        :base-url="`/api/wms/v1/master/items/supplier/${data.supplier ? data.supplier.id : 0}?type=${data.purchaseType ? data.purchaseType.id : 0}`"
                                        label="name" track-by="name" @selected="(v) => {
                                        data.warehouses[indexwh].cart.items[index].name = v
                                        data.warehouses[indexwh].cart.items[index].unit = null
                                        data.warehouses[indexwh].cart.items[index].tax = null
                                        if (bp&&tickCount>1) {
                                            data.warehouses[indexwh].cart.items[index].price = 0
                                            data.warehouses[indexwh].cart.items[index].discount = 0
                                        }
                                        tickCount++
                                        data.warehouses[indexwh].cart.items[index].renderUnits = false
                                        data.warehouses[indexwh].cart.items[index].renderTaxes = false
                                        $nextTick(() => {
                                            data.warehouses[indexwh].cart.items[index].renderTaxes = true
                                            data.warehouses[indexwh].cart.items[index].renderUnits = true
                                        })
                                        calculateItems()
                                    }"></CustomMSelect>
                                </td>
                                <td class="td vs-table--td"
                                    v-if="data.warehouses[indexwh].cart.items[index].renderUnits" style="padding: 5px; font-weight: 600;">
                                    <CustomMSelect :value="data.warehouses[indexwh].cart.items[index].unit"
                                        :key="data.warehouses[indexwh].cart.items[index].unit"
                                        :options="data.warehouses[indexwh].cart.items[index].unitOptions" :default-first="(data.warehouses[indexwh].cart.items[index].unit == null)"
                                        :base-url="`/api/wms/v1/master/item-unit/item/${data.warehouses[indexwh].cart.items[index].name ? data.warehouses[indexwh].cart.items[index].name.id : 0}`"
                                        label="name" track-by="name" @selected="(v) => {
                                        if (bp&&tickCount>1) {
                                            data.warehouses[indexwh].cart.items[index].price = 0
                                            data.warehouses[indexwh].cart.items[index].discount = 0
                                        }
                                        tickCount++
                                        data.warehouses[indexwh].cart.items[index].unit = v;
                                        calculateItems()
                                    }" :disabled="isB2B||cisDetail"></CustomMSelect>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input @input="(e) => { calculateItems() }" type="number" min="0"
                                        :disabled="isB2B||cisDetail" v-model="data.warehouses[indexwh].cart.items[index].qty"
                                        class="w-full" />
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <div class="flex vx-input-group">
                                        <vs-input type="text" min="0" @input="(e) => { calculateItems(); formatNumber(indexwh, index, 'cart', 'price') }"
                                            :disabled="(isB2B || bp || cisDetail)"
                                            v-model="data.warehouses[indexwh].cart.items[index].price" class="w-full" />
                                            <vs-button v-if="data.warehouses[indexwh].cart.items[index].price != 0 && bp" color="primary" type="line" title="Show price detail" icon-pack="feather" icon="icon-info" @click="handleProP(indexwh, index)" />
                                    </div>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input :value="data.warehouses[indexwh].cart.items[index].subtotal" disabled
                                        readonly class="w-full bg-grey-light" />
                                </td>
                                <td class="td vs-table--td" style="padding: 5px; font-weight: 600;">
                                    <div class="flex vx-input-group">
                                        <vs-input v-if="renderDiscount" type="text" min="0"
                                        @input="(e) => { calculateItems(); formatNumber(indexwh, index, 'cart', 'discount') }" :disabled="(isB2B || bp || cisDetail)"
                                        v-model="data.warehouses[indexwh].cart.items[index].discount" class="w-full" />
                                        <vs-button v-if="data.warehouses[indexwh].cart.items[index].discount != 0" color="primary" type="line" title="Show discount detail" icon-pack="feather" icon="icon-info" @click="handlePrompt(indexwh, index)" />
                                    </div>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input v-model="data.warehouses[indexwh].cart.items[index].net" disabled readonly
                                        class="w-full bg-grey-light" />
                                </td>
                                <td v-if="data.warehouses[indexwh].cart.items[index].renderTaxes"
                                    class="td vs-table--td" style="padding: 5px; font-weight: 600;">
                                    <CustomMSelect :disabled="true"
                                        :value="data.warehouses[indexwh].cart.items[index].tax"
                                        :key="data.warehouses[indexwh].cart.items[index].tax"
                                        :base-url="`/api/wms/v1/master/taxes/item/${data.warehouses[indexwh].cart.items[index].name ? data.warehouses[indexwh].cart.items[index].name.id : 0}?type=${data.purchaseType ? data.purchaseType.id : 0}`"
                                        label="name" track-by="name" @selected="(v) => {
                                        if (data.purchaseType.name == 'Bonus' || data.purchaseType.name == 'Merchandise') {
                                            data.warehouses[indexwh].cart.items[index].tax = null;
                                        }else{
                                            data.warehouses[indexwh].cart.items[index].tax = v;
                                        }                                        
                                        calculateItems();
                                    }"></CustomMSelect>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input v-model="data.warehouses[indexwh].cart.items[index].total" disabled
                                        readonly class="w-full bg-grey-light" />
                                </td>
                                <td class="td vs-table--td" style="text-align: center">
                                    <div v-if="!isB2B && !cisDetail" class="flex vx-input-group">
                                        <vs-button v-if="data.warehouses[indexwh].cart.items.length > 1"
                                            @click.stop="removeItemsRow(indexwh, index)" size="small" color="danger"
                                            icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                            title="Remove Row" />
                                        <vs-button v-if="index == data.warehouses[indexwh].cart.items.length - 1"
                                            @click.stop="addItemsRow(indexwh)" size="small" color="success"
                                            icon-pack="feather" icon="icon-plus" title="Add Row" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="8"></td>
                                <th width="10%">Total Price List</th>
                                <td width="10%"><vs-input disabled readonly class="w-full bg-grey-light"
                                        :value="formattedNumber(data.warehouses[indexwh].cart.total_price_list)" /></td>
                            </tr>
                            <tr>
                                <td colspan="8"></td>
                                <th width="10%">Total Discount</th>
                                <td width="10%"><vs-input disabled readonly class="w-full bg-grey-light"
                                        :value="formattedNumber(data.warehouses[indexwh].cart.total_discount)" /></td>
                            </tr>
                            <tr>
                                <td colspan="8"></td>
                                <th width="10%">Total Purchase</th>
                                <td width="10%"><vs-input disabled readonly class="w-full bg-grey-light"
                                        :value="formattedNumber(data.warehouses[indexwh].cart.total_purchase)" /></td>
                            </tr>
                            <tr>
                                <td colspan="8"></td>
                                <th width="10%">Total Tax</th>
                                <td width="10%"><vs-input disabled readonly class="w-full bg-grey-light"
                                        :value="formattedNumber(data.warehouses[indexwh].cart.total_tax)" /></td>
                            </tr>
                            <tr>
                                <td colspan="8"></td>
                                <th width="10%">Grand Total</th>
                                <td width="10%"><vs-input disabled readonly class="w-full bg-grey-light"
                                        :value="formattedNumber(data.warehouses[indexwh].cart.grand_total)" /></td>
                            </tr>
                            <tr>
                                <td colspan="8"></td>
                                <th width="10%">Final Total</th>
                                <td width="10%"><vs-input disabled readonly class="w-full bg-grey-light"
                                        :value="formattedNumber(data.warehouses[indexwh].cart.final_total)" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <div class="mb-6 vx-row">
                <div v-if="(!id)" class="flex flex-row-reverse w-full border-t-2 vx-col sm:w-1/1">
                    <vs-button color="danger" class="mb-2 mr-3" v-if="(data.warehouses.length > 1)"
                        @click.stop="removeWarehouse(indexwh)">Remove Warehouse</vs-button>
                    <vs-button v-if="(data.warehouses.length - 1 === indexwh)" class="mb-2 mr-3"
                        @click.stop="addWareHouse()">Add Warehouse</vs-button>
                </div>
            </div> -->
        </div>
        <div class="mb-6 vx-row">
            <div class="w-full vx-col sm:w-1/1">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);">
                    <vs-button v-if="this.bp && !cisDetail" class="mb-2 mr-3" :disabled="this.disableCal" @click="calculatePrices">{{this.calculateBtnText}}</vs-button>
                    <vs-button v-if="!cisDetail" class="mb-2 mr-3" @click="handleSubmit">Submit</vs-button>
                    <vs-button class="mt-2 ml-4" @click="handleBack()" type="border" color="danger"
                        v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
        
        <vs-prompt
            class="customPrompt"
            @close="activeProP=false"
            :active.sync="activeProP"
            :title="'Buying Price details'">
            <div class="con-exemple-prompt">
                <div>
                    <vs-table stripe :data="this.shownPrice">
                        <template slot="thead">
                            <vs-th>Buying Price Code</vs-th>
                            <vs-th>Price</vs-th>
                            <vs-th>Valid From</vs-th>
                            <vs-th>Valid To</vs-th>
                        </template>

                        <template>
                            <vs-tr :key="indextr" v-for="(tr, indextr) in this.shownPrice" >
                                <vs-td>{{tr.code}}</vs-td>
                                <vs-td class="text-right">{{formatNumDec(tr.price)}}</vs-td>
                                <vs-td>{{formatDate(tr.valid_from)}}</vs-td>
                                <vs-td>{{formatDate(tr.valid_to)}}</vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
        </vs-prompt>

        <vs-prompt
            class="customPrompt"
            @close="activePrompt=false"
            :active.sync="activePrompt"
            :title="'Discount details'">
            <div class="con-exemple-prompt">
                <div>
                    <vs-table stripe :data="this.shownDisc">
                        <template slot="thead">
                            <vs-th>Discount Code</vs-th>
                            <vs-th>Calculation Model</vs-th>
                            <vs-th>Discount</vs-th>
                            <vs-th>Discount Model</vs-th>
                            <vs-th>Multiply Per / By</vs-th>
                            <vs-th>Discount Amount</vs-th>
                        </template>

                        <template>
                            <vs-tr :key="indextr" v-for="(tr, indextr) in this.shownDisc" >
                                <vs-td>{{tr.disc_code}}</vs-td>
                                <vs-td>{{tr.calc_model}}</vs-td>
                                <vs-td class="text-right">{{formatNumDec(tr.discount)}}</vs-td>
                                <vs-td>{{tr.disc_model}}</vs-td>
                                <vs-td>{{tr.multiply_per}}</vs-td>
                                <vs-td class="text-right">{{formattedNumber(tr.disc_amount)}}</vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
        </vs-prompt>        
    </vx-card>
</template>

<script>
import Date from "@/components/Date.vue";
import CustomMSelect from "./components/CustomMultiSelect.vue";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

const initChargeItem = { id: 0, renderTax: true, name: null, amount: 0, tax: null, tax_amount: 0, subtotal: 0 };
const initCartItem = { id: 0, renderUnits: true, renderTaxes: true, unitOptions: [], name: null, unit: null, qty: 0, price: 0, qty_pcs: 0, price_pcs: 0, sub_tax: 0, subtotal: 0, discount: 0, net: 0, tax: null, total: 0, detailDiscount: [], detailPrice: [] };
export default {
    components: {
        Date,
        CustomMSelect,
        vSelect,
        Datepicker,
    },
    mounted() {
        this.id = this.$route.params.id;
        this.isDetail = (this.$route.params.mode == "detail")
        this.isApproval = (this.$route.params.isApproval == "Approval")
        if (this.isDetail){
            this.title = "Detail Purchase Order"
        } else if (this.id) {
            this.title = "Edit Purchase Order"
        } else if (!this.id) {
            this.title = "Purchase Order"
        }     
        this.data.is_buying_price = this.$route.query.bp;
        this.bp = Number(this.$route.query.bp) === 1;
        this.data.sources = "PO Manual"
        if (Number(this.$route.query.bp) === 1) {
            this.data.sources = "PO With Buying Price"
        }
        if (this.id) {
            this.initData();
        } else {
            this.$vs.loading();
            setTimeout(this.$vs.loading.close, 3200);
        }
    },
    data() {
        return {
            title: "",
            isDetail: false,
            isApproval: false,
            renderForm: true,
            renderDiscount: true,
            renderPurchaseType: true,
            activePrompt: false,
            activeProP: false,
            shownPrice: [],
            shownDisc: [],
            calculateBtnText: "Calculate",
            disableCal: false,
            dialog: {
                save: false,
            },
            disabledDates: {
                to: moment().subtract(1, "days").toDate()
            },
            id: null,
            createdBy: null,
            isB2B: false,
            bp: false,
            draft:true,
            tickCount:2,
            priceResp: [],
            data: {
                po_id: 0,
                count_print: 0,
                is_buying_price: 0,
                po_number: "",
                company_id: 0,
                eta: moment().add(1, "days").toDate(),
                date: moment().toDate(),
                posting_date: moment().toDate(),
                external_code: null,
                supplier: null,
                sources: "",
                paymentTerms: null,
                purchaseGroup: null,
                purchaseType: null,
                warehouses: [
                    {
                        warehouse: null,
                        renderItems: true,
                        currency: null,
                        status: "",
                        charge_type: "",
                        prorate_method: "",
                        po_virtual_code: null,
                        notes: "",
                        history_notes:null,
                        charge: {
                            taxes: [
                                { ...initChargeItem },
                            ],
                            total_charges: 0,
                            total_taxes: 0,
                            grand_total: 0
                        },
                        cart: {
                            items: [
                                { ...initCartItem },
                            ],
                            total_price_list: 0,
                            total_discount: 0,
                            total_purchase: 0,
                            total_tax: 0,
                            grand_total: 0,
                            final_total: 0
                        }
                    },
                ],

            },
        }
    },
    methods: {
        handleLogging(v) {
            console.log("value:", v);
        },
        formatNumber(indexWh, index, type, name) {
            if (type == "charges") {
                var amount = Number(String(this.data.warehouses[indexWh].charge.taxes[index][name]).replace(/\./g, ''))
                if (!isNaN(amount)){
                    this.data.warehouses[indexWh].charge.taxes[index][name] = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }else {
                    this.data.warehouses[indexWh].charge.taxes[index][name] = 0
                }
            }
            if (type == "cart"){                
                amount = Number(String(this.data.warehouses[indexWh].cart.items[index][name]).replace(/\./g, ''))
                if (!isNaN(amount)){
                    this.data.warehouses[indexWh].cart.items[index][name] = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }else {
                    this.data.warehouses[indexWh].cart.items[index][name] = 0
                }
            }
        },
        handleSubmit() {
            var submit = true;
            var requestBody = [];
            this.data.warehouses.forEach((warehouse, i) => {
                var arrCharges = []
                try {
                    warehouse.charge.taxes.forEach((tax) => {
                        var charge = {
                            id: tax.id,
                            purchase_order_id: Number(this.data.po_id),
                            po_virtual_code: warehouse.po_virtual_code,
                            charge_name: tax.name.name,
                            amount: Number(String(tax.amount).replace(/\./g, '')),
                            tax_amount: Number(String(tax.tax_amount).replace(/\./g, '')),
                        }
                        if (tax.tax) {
                            charge.tax_rate = Number(tax.tax.rate);
                            charge.tax_id = tax.tax.id;
                        } else {
                            charge.tax_rate = 0;
                            charge.tax_id = 101;
                        }
                        arrCharges.push(charge);
                    });
                } catch (e) {
                    arrCharges = [];
                }

                var arrDetails = []
                var err = []
                try {
                    var itemUnitMap = new Map();
                    warehouse.cart.items.forEach((item, index) => {
                        if (item.name == null) {
                            err.push(`Item is required`)
                            throw new Error(err.join("; "))
                        }
                        if (Number(item.qty) < 1) {
                            err.push(`quantity cannot be zero or negative value`)
                        }
                        if (Number(String(item.total).replace(/\./g, '')) < 0) {
                            err.push(`total cannot be negative value`)
                        }
                        if (this.bp && item.price == 0) {
                            err.push(`cannot use expired or 0 empty buying price for ${item.name.name}`)
                        }
                        
                        if (itemUnitMap.has(item.unit.id)) {
                            err.push(`Found duplicate Item unit '${item.unit.name}' for item '${item.name.name}'`)
                        }else{
                            itemUnitMap.set(item.unit.id, true)
                        }

                        var arrDiscount = [];
                        item.detailDiscount.forEach(el => {
                            var lineDiscount = {
                                id: el.disc_id,
                                purchase_order_line_id: item.id,
                                supplier_discount_line_id: el.disc_line_id,
                                calculation_model: el.calc_model,
                                discount_model: el.disc_model,
                                discount: parseFloat(el.discount),
                                discount_amount: parseFloat(el.disc_amount),
                            }
                            arrDiscount.push(lineDiscount)
                        });

                        var line = {
                            id: item.id,
                            purchase_order_id: Number(this.data.po_id),
                            item_unit_id: item.unit.id,
                            item_name: item.name.name,
                            item_unit: item.unit.name,
                            quantity: Number(item.qty),
                            quantity_pcs: Number(item.qty_pcs),
                            price: Number(String(item.price).replace(/\./g, '')),
                            price_pcs: Number(item.price_pcs),
                            discount: Number(String(item.discount).replace(/\./g, '')),
                            subtotal: parseFloat(String(item.subtotal).replace(/\./g, '')),
                            tax: Number(item.sub_tax),
                            net: parseFloat(String(item.net).replace(/\./g, '')),
                            sku_code: item.name.sku_code,
                            sku_code_supplier: item.name.sku_code_supplier,
                            purchase_discount: arrDiscount,
                            price_line_id: (item.detailPrice.length > 0) ? item.detailPrice[0].id : 0,
                        }
                        if (item.tax) {
                            line.tax_rate = Number(item.tax.rate);
                            line.tax_id = item.tax.id;
                        } else {
                            line.tax_rate = 0;
                            line.tax_id = 101;
                        }
                        arrDetails.push(line);
                    });
                    if (err.length > 0) {
                        throw new Error(err.join("; "))
                    }
                } catch (e) {
                    this.$vs.dialog({
                        type: "confirm",
                        color: "danger",
                        title: `Error`,
                        text: e,
                    });
                    submit = false;
                }

                try {
                    if (arrDetails.length > 0) {
                        requestBody.push({
                            from_warehouse_id: Number(warehouse.warehouse.id),
                            id: Number(this.id),
                            charge_type: warehouse.charge_type,
                            prorate_method: warehouse.prorate_method,
                            code: this.data.po_number,
                            external_code: this.data.external_code,
                            virtual_code: warehouse.po_virtual_code,
                            company_id: Number(this.data.company_id),
                            supplier_id: this.data.supplier.id,
                            supplier_code: this.data.supplier.code,
                            supplier_name: this.data.supplier.name,
                            supplier_contact_name: this.data.supplier.contactName,
                            supplier_city: this.data.supplier.city,
                            supplier_address: this.data.supplier.address,
                            supplier_phone: this.data.supplier.phone,
                            date: this.data.date,
                            is_internal: "",
                            sub_total: Number(warehouse.cart.total_price_list),
                            tax: Number(warehouse.cart.total_tax),
                            total: Number(warehouse.cart.final_total),
                            eta: this.data.eta,
                            currency_code: warehouse.currency.code,
                            total_charge: arrCharges.length > 0 ? Number(warehouse.charge.total_charges) : 0,
                            discount: Number(warehouse.cart.total_discount),
                            // is_partial: "",
                            notes: warehouse.notes,
                            payment_term_id: this.data.paymentTerms.id,
                            payment_term_name: this.data.paymentTerms.name,
                            payment_method_name: this.data.paymentTerms.payment_method?this.data.paymentTerms.payment_method.name:"",
                            purchase_type_id: this.data.purchaseType.id,
                            purchase_group_id: this.data.purchaseGroup.id,
                            is_buying_price: Number(this.data.is_buying_price).toString(),
                            // notes_approval: "",
                            sources: this.data.sources,
                            status: warehouse.status,
                            status_invoice: 0,
                            // external_code: "",
                            posting_date: this.data.posting_date,
                            operating_unit_id: warehouse.operating_unit_id,
                            operating_unit_code: warehouse.operating_unit_code,
                            status_reversal: 0,
                            original_tax: arrCharges.length > 0 ? Number(warehouse.charge.total_taxes) : 0,
                            count_print: this.data.count_print,
                            purchase_charges: arrCharges,
                            purchase_detail: arrDetails,
                        })
                    }
                } catch (e) {
                    this.$vs.dialog({
                        type: "confirm",
                        color: "danger",
                        title: `Error`,
                        text: `Check your input, some field have missing value`,
                    });
                    submit = false;
                }
            });
            console.log(requestBody);
            if (requestBody.length > 0 && submit) {
                this.$vs.loading();
                if (this.id) {
                    this.$http
                        .post(`${this.$store.state.purchaseOrder.baseUrlPath}/${this.id}`, requestBody)
                        .then(resp => {
                            if (resp.code == 200) {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    type: "confirm",
                                    color: "success",
                                    title: `Updated successfully`,
                                    text: "Accept to finish editing or cancel to continue editing.",
                                    accept: this.handleBack,
                                });
                            } else {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    type: "confirm",
                                    color: "danger",
                                    title: `Error`,
                                    text: `${resp.code} : ${resp.message}`,
                                });
                            }
                        });
                } else {
                    this.$http
                        .post(this.$store.state.purchaseOrder.baseUrlPath, requestBody)
                        .then(resp => {
                            if (resp.code == 200) {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    type: "confirm",
                                    color: "success",
                                    title: `Information`,
                                    text: "Purchase order with code "+resp.data.created_po+" successfully created",
                                    accept: this.handleBack,
                                    "accept-text": "OK",
                                    "button-cancel": false,
                                });
                                // this.$vs.notify({
                                //     color: "success",
                                //     title: "Success",
                                //     text: "Purchase order with code "+resp.data.created_po+" successfully created",
                                //     position: "top-right",
                                //     iconPack: "feather",
                                //     icon: "icon-x-circle",
                                // });
                            } else {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    type: "confirm",
                                    color: "danger",
                                    title: `Error`,
                                    text: `${resp.code} : ${resp.message}`,
                                });
                            }
                        });
                }
            }
        },
        handleBack() {
            this.$vs.loading();
            if (!this.isApproval) {
                this.$router.push({
                    name: this.$store.state.purchaseOrder.baseRouterName,
                });
            } else {
                this.$router.push({
                    name: this.$store.state.purchaseOrder.baseRouterName+"-approval",
                });
            }
            this.$vs.loading.close();
        },
        handleProP(indexwh, index) {
            this.shownPrice = []
            this.activeProP = true
            this.shownPrice = this.data.warehouses[indexwh].cart.items[index].detailPrice
            console.log(this.data.warehouses[indexwh].cart.items[index].detailPrice)
        },
        handlePrompt(indexwh, index) {
            this.shownDisc = []
            this.activePrompt = true
            this.shownDisc = this.data.warehouses[indexwh].cart.items[index].detailDiscount
        },
        async calculatePrices() {
            var itemUnitMap = new Map();
            var unitMap = new Map();
            var itemMap = new Map();
            var skuMap = new Map();
            var warehouseMap = new Map();
            var found_item_unit = [];
            var found_item = [];
            var found_wh = [];
            var hasZeroQty = false;
            try {
                let isWarehouseEmpty = true;
                let isItemEmpty = true;
                let isUnitEmpty = true;
                let isSupplierEmpty = true;

                if (this.data.supplier != null) {
                    isSupplierEmpty = false;
                }

                this.data.warehouses.forEach(wh => {                    
                    if (wh.warehouse && wh.warehouse.id && wh.warehouse.name) {
                        isWarehouseEmpty = false;
                        warehouseMap.set(wh.warehouse.id, wh.warehouse.name);
                    }
                    
                    wh.cart.items.forEach(x => {                        
                        if (x.unit && x.unit.id && x.name.name) {
                            itemUnitMap.set(x.unit.id, x.name.name);
                        }
                        if (x.unit && x.unit.item_id && x.name.name) {
                            isItemEmpty = false;
                            itemMap.set(x.unit.item_id, x.name.name);
                            skuMap.set(x.unit.item_id, x.name.sku_code);
                        }
                        if (x.unit && x.unit.unit_id && x.name.name) {
                            isUnitEmpty = false;
                            unitMap.set(x.unit.unit_id, x.name.name);
                        }
                    });
                });

                if (isSupplierEmpty) {
                    throw new Error('SupplierEmpty');
                } else if (isWarehouseEmpty) {
                    throw new Error('WarehouseEmpty');
                } else if (isItemEmpty) {
                    throw new Error('ItemEmpty');
                } else if (isUnitEmpty) {
                    throw new Error('UnitEmpty');
                }
            } catch (error) {
                let errorMessage = '';
                switch (error.message) {
                    case 'SupplierEmpty':
                        errorMessage = 'Supplier is required.';
                        break;
                    case 'WarehouseEmpty':
                        errorMessage = 'Warehouse is required.';
                        break;
                    case 'ItemEmpty':
                        errorMessage = 'Item is required.';
                        break;
                    case 'UnitEmpty':
                        errorMessage = 'Unit is required.';
                        break;
                    default:
                        errorMessage = 'An unexpected error occurred';
                }

                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Error`,
                    text: errorMessage,
                });
                return;
            }

            this.disableCal = true
            this.calculateBtnText = "Processing..."

            try {
                this.priceResp = [];
                const priceResp = await this.$http.post(`/api/wms/v1/wms/supplier-price/item-prices`, {
                    supplier_id: this.data.supplier.id,
                    warehouse_id: this.data.warehouses[0].warehouse.id,
                    sku_codes: Array.from(skuMap.values())
                });

                if (priceResp.data) {
                    this.priceResp = priceResp.data
                    this.data.warehouses.forEach((wh, i) => {
                        wh.cart.items.forEach((item, j) => {
                            this.data.warehouses[i].cart.items[j].detailPrice = [];
                            priceResp.data.forEach(data => {
                                var qty = Number(item.qty);                                
                                if (qty <= 0) {
                                    hasZeroQty = true;
                                    return;
                                }
                                if (wh.warehouse.id === data.warehouse_id) {
                                    if (data.item_unit_id === item.unit.id && (Number(data.qty_from) <= qty && Number(data.qty_to) >= qty)) {
                                        found_item_unit.push(data.item_unit_id);
                                        var amount = Math.floor(Number(data.price));
                                        if (!isNaN(amount)) {
                                            this.data.warehouses[i].cart.items[j].price = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                        }
                                        this.data.warehouses[i].cart.items[j].detailPrice.push({
                                            "id": data.id,
                                            "code": data.code,
                                            "price": data.price,
                                            "valid_from": data.valid_from,
                                            "valid_to": data.valid_to,
                                        });
                                    } else if (!found_item_unit.includes(item.unit.id)) {
                                        this.data.warehouses[i].cart.items[j].price = 0;
                                    }
                                }
                            });
                        });
                    });                    

                    let difference = Array.from(itemUnitMap.keys()).filter(x => !found_item_unit.includes(x));
                    if (difference.length > 0) {
                        var message = "";
                        if (hasZeroQty) {
                            message = "Quantity cannot be zero or negative value"
                        } else {
                            message = "price not found for item: "
                            difference.forEach((x, i) => {
                                if (difference.length === 1) {
                                    message += `\n${itemUnitMap.get(x)}.`;
                                } else if (i == difference.length - 1) {
                                    message += `\nand ${itemUnitMap.get(x)}.`;
                                } else {
                                    message += `\n${itemUnitMap.get(x)}, `;
                                }
                            });
                        }
                        this.$vs.dialog({
                            type: "confirm",
                            color: "danger",
                            title: `Error`,
                            text: message,
                        });
                    }
                    found_item_unit = [];
                } else {
                    this.$vs.dialog({
                        type: "confirm",
                        color: "danger",
                        title: `Information`,
                        text: `Buying Price not found`,
                    });
                }
                this.calculateItems();
            } catch (error) {
                console.error(error);
            }

            // set discount to 0 before calculate
            this.data.warehouses.forEach((wh, i) => {
                wh.cart.items.forEach((x, j) => {
                    this.data.warehouses[i].cart.items[j].discount = 0;
                });
            });

            try {
                const discountResp = await this.$http.post(`/api/wms/v1/wms/supplier-discount/discount`, {
                    supplier_id: this.data.supplier.id,
                    unit_ids: Array.from(unitMap.keys()),
                    item_ids: Array.from(itemMap.keys()),
                    warehouse_ids: Array.from(warehouseMap.keys()),
                });

                if (discountResp.data) {
                    this.data.warehouses.forEach((wh, i) => {
                        wh.cart.items.forEach((x, j) => {
                            this.data.warehouses[i].cart.items[j].detailDiscount = [];
                            var currDisc = this.data.warehouses[i].cart.items[j].discount;
                            if (currDisc != 0) {
                                currDisc = Number(String(currDisc).replace(/\./g, ''));
                            }
                            var qty = this.data.warehouses[i].cart.items[j].qty;
                            var price = this.data.warehouses[i].cart.items[j].price;
                            var xAmountUom = x.unit.amount_uom;
                            if (price != 0) {
                                price = Number(String(price).replace(/\./g, ''));
                            }
                            var tempQty = qty;
                            var tempQtyConv = 0;
                            console.log("diskon data", discountResp.data)
                            var removedDiscAmounts = [];

                            discountResp.data.forEach(r => {
                                if (wh.warehouse.id == r.warehouse_id) {
                                    found_wh.push(wh.warehouse.id);
                                    if ((x.unit.item_id == r.item_id || x.name.category_id != 0 && x.name.category_id == r.category_id || r.item_id == 0)) {
                                        if ((x.unit.unit_id == r.unit_id)) {
                                            found_item.push(r.item_id);
                                            if (r.calculation_model == "Final" && (qty >= r.from_qty && qty <= r.to_qty || qty > r.to_qty)) {
                                                if (r.discount_model == "Amount") {
                                                    var discMulti = 0;
                                                    var isMulti = false;
                                                    var multiBy = 0;
                                                    var isDiscount = false;
                                                    if (r.multiply == 1 && r.unit != "" && r.unit != 0) {
                                                        var multi = Math.floor(qty / r.unit);
                                                        if (multi > 0) {
                                                            currDisc += Math.round(r.discount * multi);
                                                            discMulti = Math.round(r.discount * multi);
                                                            isMulti = true
                                                            multiBy = multi
                                                            isDiscount = true
                                                        }
                                                    } else {
                                                        currDisc += Math.round(r.discount);
                                                        isDiscount = true
                                                    }
                                                    if (isDiscount) {
                                                        this.data.warehouses[i].cart.items[j].detailDiscount.push({
                                                            "disc_id": null,
                                                            "disc_code": r.code,
                                                            "disc_line_id": r.discount_line_id,
                                                            "calc_model": "Final",                                                        
                                                            "disc_model": "Amount",
                                                            "discount": r.discount,
                                                            "disc_amount": discMulti == 0 ? Math.round(r.discount) : Math.round(discMulti),
                                                            "multiply_per": isMulti ? r.unit+" "+r.hu+" / "+multiBy : "",
                                                        });
                                                    }
                                                } else {
                                                    var total = qty * price;
                                                    var discount = (total * r.discount) / 100;
                                                    if (!isNaN(discount)) {
                                                        currDisc += Math.round(discount);
                                                        this.data.warehouses[i].cart.items[j].detailDiscount.push({
                                                            "disc_id": null,
                                                            "disc_code": r.code,
                                                            "disc_line_id": r.discount_line_id,
                                                            "calc_model": "Final",
                                                            "disc_model": "%",
                                                            "discount": r.discount,
                                                            "disc_amount": Math.round(discount)
                                                        });
                                                    }
                                                }
                                                console.log('final ', currDisc);
                                            } else if (r.calculation_model == "Step") {
                                                total = qty * price;
                                                var thres = (r.to_qty - (r.from_qty - 1));
                                                tempQty = tempQty - thres;
                                                if (tempQty >= 0) {
                                                    total = thres * price;
                                                    discount = (total * r.discount) / 100;
                                                    if (!isNaN(discount)) {
                                                        currDisc += Math.round(discount);
                                                        this.data.warehouses[i].cart.items[j].detailDiscount.push({
                                                            "disc_id": null,
                                                            "disc_code": r.code,
                                                            "disc_line_id": r.discount_line_id,
                                                            "calc_model": "Step",
                                                            "disc_model": "%",
                                                            "discount": r.discount,
                                                            "disc_amount": Math.round(discount)
                                                        });
                                                    }
                                                } else {
                                                    if ((tempQty + thres) > 0) {
                                                        total = (tempQty + thres) * price;
                                                        discount = (total * r.discount) / 100;
                                                        if (!isNaN(discount)) {
                                                            currDisc += Math.round(discount);
                                                            this.data.warehouses[i].cart.items[j].detailDiscount.push({
                                                                "disc_id": null,
                                                                "disc_code": r.code,
                                                                "disc_line_id": r.discount_line_id,
                                                                "calc_model": "Step",
                                                                "disc_model": "%",
                                                                "discount": r.discount,
                                                                "disc_amount": Math.round(discount)
                                                            });
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            if (r.amount_uom != 0) {
                                                var qtyConv = qty
                                                var priceConv = 0
                                                this.priceResp.forEach(p => {
                                                    if (r.item_unit_id == p.item_unit_id) {
                                                        priceConv = p.price
                                                    }
                                                })
                                                qtyConv = (qty * xAmountUom) / r.amount_uom
                                                console.log('qtyConv', qtyConv)
                                                console.log('priceConv', priceConv)
                                                if (r.calculation_model == "Final" && (qtyConv >= r.from_qty && qtyConv <= r.to_qty || qtyConv > r.to_qty)) {
                                                    if (r.discount_model == "Amount") {
                                                        discMulti = 0;
                                                        isMulti = false;
                                                        multiBy = 0;
                                                        isDiscount = false
                                                        if (r.multiply == 1 && r.unit != "" && r.unit != 0) {
                                                            multi = Math.floor(qtyConv / r.unit);
                                                            if (multi > 0) {
                                                                currDisc += Math.round(r.discount * multi);
                                                                discMulti = Math.round(r.discount * multi);
                                                                isMulti = true
                                                                multiBy = multi
                                                                isDiscount = true
                                                            }
                                                        } else {
                                                            currDisc += Math.round(r.discount);
                                                            isDiscount = true
                                                        }
                                                        if (isDiscount) {
                                                            this.data.warehouses[i].cart.items[j].detailDiscount.push({
                                                                "disc_id": null,
                                                                "disc_code": r.code,
                                                                "disc_line_id": r.discount_line_id,
                                                                "calc_model": "Final",                                                        
                                                                "disc_model": "Amount",
                                                                "discount": r.discount,
                                                                "disc_amount": discMulti == 0 ? Math.round(r.discount) : Math.round(discMulti),
                                                                "multiply_per": isMulti ? r.unit+" "+r.hu+" / "+multiBy : "",
                                                            });
                                                        }
                                                    } else {
                                                        total = qtyConv * priceConv;
                                                        discount = (total * r.discount) / 100;
                                                        if (!isNaN(discount)) {
                                                            currDisc += Math.round(discount);
                                                            this.data.warehouses[i].cart.items[j].detailDiscount.push({
                                                                "disc_id": null,
                                                                "disc_code": r.code,
                                                                "disc_line_id": r.discount_line_id,
                                                                "calc_model": "Final",
                                                                "disc_model": "%",
                                                                "discount": r.discount,
                                                                "disc_amount": Math.round(discount)
                                                            });
                                                        }
                                                    }
                                                } else if (r.calculation_model == "Step") {
                                                    total = qtyConv * priceConv;
                                                    thres = (r.to_qty - (r.from_qty - 1));
                                                    tempQtyConv = (tempQtyConv == 0 ? qtyConv : tempQtyConv) - thres;
                                                    if (tempQtyConv >= 0) {
                                                        total = thres * priceConv;
                                                        discount = (total * r.discount) / 100;
                                                        if (!isNaN(discount)) {
                                                            currDisc += Math.round(discount);
                                                            this.data.warehouses[i].cart.items[j].detailDiscount.push({
                                                                "disc_id": null,
                                                                "disc_code": r.code,
                                                                "disc_line_id": r.discount_line_id,
                                                                "calc_model": "Step",
                                                                "disc_model": "%",
                                                                "discount": r.discount,
                                                                "disc_amount": Math.round(discount)
                                                            });
                                                        }
                                                    } else {
                                                        if ((tempQtyConv + thres) > 0) {
                                                            total = (tempQtyConv + thres) * priceConv;
                                                            discount = (total * r.discount) / 100;
                                                            if (!isNaN(discount)) {
                                                                currDisc += Math.round(discount);
                                                                this.data.warehouses[i].cart.items[j].detailDiscount.push({
                                                                    "disc_id": null,
                                                                    "disc_code": r.code,
                                                                    "disc_line_id": r.discount_line_id,
                                                                    "calc_model": "Step",
                                                                    "disc_model": "%",
                                                                    "discount": r.discount,
                                                                    "disc_amount": Math.round(discount)
                                                                });
                                                            }
                                                        }
                                                    }
                                                }
                                            }                                            
                                        }
                                    } else if (!found_item.includes(x.unit.item_id)) {
                                        this.data.warehouses[i].cart.items[j].discount = 0;
                                    }
                                }
                            });
                            
                            let uniqueDetailDiscount = [];
                            let seenDiscLineIds = new Set();
                            this.data.warehouses[i].cart.items[j].detailDiscount.forEach(detail => {
                                if (!seenDiscLineIds.has(detail.disc_line_id)) {
                                    seenDiscLineIds.add(detail.disc_line_id);
                                    uniqueDetailDiscount.push(detail);
                                } else {
                                    removedDiscAmounts.push(detail.disc_amount);
                                }
                            });
                            this.data.warehouses[i].cart.items[j].detailDiscount = uniqueDetailDiscount;

                            // Adjust currDisc by subtracting the sum of removed disc_amount
                            if (removedDiscAmounts.length > 0) {
                                let totalRemovedDiscount = removedDiscAmounts.reduce((acc, val) => acc + val, 0);
                                currDisc -= totalRemovedDiscount;
                            }

                            if (currDisc != 0) {
                                currDisc = Math.round(Number(currDisc))
                                this.data.warehouses[i].cart.items[j].discount = currDisc.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                            }
                        });
                    });                    
                    this.calculateItems();
                }
            } catch (error) {
                console.error(error);
            }
            this.disableCal = false
            this.calculateBtnText = "Calculate"
        },
        addTaxRow(indexwh) {
            this.data.warehouses[indexwh].charge.taxes.push({ ...initChargeItem });
        },
        removeTaxRow(indexwh, i) {
            this.data.warehouses[indexwh].charge.taxes.splice(i, 1)
            this.calculateCharges();
        },
        addItemsRow(indexwh) {
            this.data.warehouses[indexwh].cart.items.push({ ...initCartItem });
        },
        removeItemsRow(indexwh, i) {
            this.data.warehouses[indexwh].cart.items.splice(i, 1);
            this.calculateItems();
        },
        addWareHouse() {
            this.data.warehouses.push(
                {
                    warehouse: null,
                    renderItems: true,
                    currency: null,
                    status: "",
                    charge_type: "",
                    prorate_method: "",
                    po_virtual_code: null,
                    notes: "",
                    charge: {
                        taxes: [
                            { ...initChargeItem },
                        ],
                        total_charges: 0,
                        total_taxes: 0,
                        grand_total: 0
                    },
                    cart: {
                        items: [
                            { ...initCartItem },
                        ],
                        total_price_list: 0,
                        total_discount: 0,
                        total_purchase: 0,
                        total_tax: 0,
                        grand_total: 0,
                        final_total: 0
                    }
                },
            );
        },
        removeWarehouse(i) {
            this.data.warehouses.splice(i, 1)
        },
        calculateCharges() {
            this.data.warehouses.forEach((wa, waIdx) => {
                if (wa.charge.taxes) {
                    var total_charge = 0;
                    var total_tax = 0;
                    var grand_total = 0;
                    wa.charge.taxes.forEach((item, index) => {
                        const default_tax_rate = item.name ? Number(item.name.default_tax_rate) : 0;
                        const tax_rate = item.tax ? Number(item.tax.rate) : default_tax_rate;
                        var tax_amount = Number(String(item.amount).replace(/\./g, '')) * (tax_rate / 100);
                        var subtotal = Number(String(item.amount).replace(/\./g, '')) + Number(tax_amount);
                        total_charge += Number(String(item.amount).replace(/\./g, ''));
                        total_tax += tax_amount;
                        grand_total += subtotal;
                        this.data.warehouses[waIdx].charge.taxes[index].tax_amount = this.formattedNumber(tax_amount);
                        this.data.warehouses[waIdx].charge.taxes[index].subtotal = this.formattedNumber(subtotal);
                    });
                    this.data.warehouses[waIdx].charge.total_charges = total_charge.toFixed(2);
                    this.data.warehouses[waIdx].charge.total_taxes = total_tax.toFixed(2);
                    this.data.warehouses[waIdx].charge.grand_total = grand_total.toFixed(2);
                    // this.data.warehouses[waIdx].cart.final_total = grand_total.toFixed(2); //(Number(this.data.warehouses[waIdx].cart.grand_total) + grand_total).toFixed(2);
                }
            });
        },
        refreshLineData() {
            this.data.warehouses.forEach((wa, waIdx) => {
                wa.charge = {
                            taxes: [
                                { ...initChargeItem },
                            ],
                            total_charges: 0,
                            total_taxes: 0,
                            grand_total: 0
                        }
                wa.cart = {
                            items: [
                                { ...initCartItem },
                            ],
                            total_price_list: 0,
                            total_discount: 0,
                            total_purchase: 0,
                            total_tax: 0,
                            grand_total: 0,
                            final_total: 0
                        }
            })
        },
        refreshForm() {
            this.renderForm = false;
            this.$nextTick(() => {
                this.renderForm = true;
            })
            // if (this.data.purchaseType.name == "Bonus" || this.data.purchaseType.name == "Merchandise"){
            //     this.data.warehouses[0].cart.items.forEach((val, idx) => {
            //         val.tax = null;
            //     })
            // }
            this.calculateCharges();
            this.calculateItems();
        },
        calculateItems() {
            this.data.warehouses.forEach((wa, waIdx) => {
                if (wa.cart.items) {
                    var total_price_list = 0;
                    var total_discount = 0;
                    var total_purchase = 0;
                    var total_tax = 0;
                    var grand_total = 0;
                    wa.cart.items.forEach((item, index) => {
                        var tax = Number(item.tax ? item.tax.rate : 0) / 100;
                        if (item.tax){
                            if (!item.tax.generate_tax_invoice) {
                                tax = 0;
                            }
                        }
                        // if (this.data.purchaseType.name == "Bonus" || this.data.purchaseType.name == "Merchandise"){
                        //     this.data.warehouses[waIdx].cart.items[index].tax = null;
                        //     tax = 0;
                        // }
                        var sub_total = Number(item.qty ? item.qty : 0) * (item.price != 0 ? Number(String(item.price).replace(/\./g, '')) : 0);
                        var net = sub_total - (item.discount != 0 ? Number(String(item.discount).replace(/\./g, '')) : 0);
                        var sub_tax = net * tax
                        var total = sub_tax + net
                        this.data.warehouses[waIdx].cart.items[index].subtotal = this.formattedNumber(sub_total);
                        this.data.warehouses[waIdx].cart.items[index].net = this.formattedNumber(net);
                        this.data.warehouses[waIdx].cart.items[index].total = this.formattedNumber(total);
                        this.data.warehouses[waIdx].cart.items[index].sub_tax = sub_tax;
                        this.data.warehouses[waIdx].cart.items[index].qty_pcs = (Number(item.qty) * Number(item.unit.amount_uom)).toFixed(2);
                        this.data.warehouses[waIdx].cart.items[index].price_pcs = ((item.price != 0 ? Number(String(item.price).replace(/\./g, '')) : 0) / Number(item.unit.amount_uom)).toFixed(2);
                        total_price_list += sub_total;
                        total_discount += Number(String(item.discount).replace(/\./g, ''));
                        total_purchase += net;
                        total_tax += sub_tax;
                        grand_total += total;
                    });
                    this.data.warehouses[waIdx].cart.total_price_list = total_price_list.toFixed(2);
                    this.data.warehouses[waIdx].cart.total_discount = total_discount.toFixed(2);
                    this.data.warehouses[waIdx].cart.total_purchase = total_purchase.toFixed(2);
                    this.data.warehouses[waIdx].cart.total_tax = total_tax.toFixed(2);
                    this.data.warehouses[waIdx].cart.grand_total = grand_total.toFixed(2);
                    this.data.warehouses[waIdx].cart.final_total = grand_total.toFixed(2);//(Number(this.data.warehouses[waIdx].charge.grand_total) + grand_total).toFixed(2);
                }

            });
        },
        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.purchaseOrder.baseUrlPath}/${this.id}`, {})
                .then((resp) => {
                    if (resp.code == 200) {
                        const dt = resp.data;
                        const taxes = [];
                        const items = [];
                        this.createdBy = resp.message;
                        this.isB2B = dt.purchase_type && dt.purchase_type.name == 'Back2Back';
                        this.bp = dt.is_buying_price === '1';
                        this.draft=dt.status !== "4"
                        if (this.bp) {
                            this.tickCount=0;
                        }
                        if (dt.purchase_detail) {
                            dt.purchase_detail.forEach(item => {
                                var arrDisc = []
                                item.purchase_discount.forEach(disc => {
                                    var isMulti = false
                                    var unitPer = 0
                                    var multiBy = 0
                                    if (disc.discount_line.Multiply == 1 && disc.discount_line.Unit != "") {
                                        isMulti = true                                        
                                        unitPer = Number(disc.discount_line.Unit)
                                        multiBy = Number(disc.discount_amount / disc.discount)
                                    }                                    
                                    var lineDisc = {
                                        "disc_id": disc.id,
                                        "disc_code": disc.discount_line.supplier_discount.Code,
                                        "disc_line_id": disc.supplier_discount_line_id,
                                        "calc_model": disc.calculation_model, 
                                        "disc_model": disc.discount_model,
                                        "discount": disc.discount,
                                        "disc_amount": disc.discount_amount,
                                        "multiply_per": isMulti ? unitPer+" / "+multiBy : "",
                                    }
                                    arrDisc.push(lineDisc)
                                })
                                var arrPrice = []
                                if (item.purchase_price){
                                    var dPrice = {
                                        "id": item.purchase_price[0].id,
                                        "code": item.purchase_price[0].supplier_price.Code,
                                        "price": item.purchase_price[0].Price,
                                        "valid_from": item.purchase_price[0].ValidFrom,
                                        "valid_to": item.purchase_price[0].ValidTo,
                                    }
                                    arrPrice.push(dPrice)
                                }
                                console.log(arrDisc)
                                items.push({
                                    id: item.id, renderUnits: true, renderTaxes: true, name: item.item_unitx.item,
                                    unit: JSON.parse(JSON.stringify({ id: item.item_unit_id, item_id: item.item_unitx.item_id, unit_id: item.item_unitx.unit_id, name: item.item_unit, amount_uom: item.item_unitx.amount_uom, level: item.item_unitx.level})), 
                                    qty: Number(item.quantity), price: this.formattedNumber(item.price), qty_pcs: Number(item.quantity_pcs),
                                    price_pcs: Number(item.price_pcs), sub_tax: 0, subtotal: Number(item.subtotal),
                                    discount: this.formattedNumber(item.discount), net: Number(item.net), tax: item.tax_model, total: 0,
                                    detailDiscount : arrDisc, detailPrice : arrPrice,
                                })
                            });
                        } else {
                            items.push({ ...initCartItem })
                        }
                        if (dt.purchase_charges) {
                            dt.purchase_charges.forEach((item) => {
                                taxes.push({ id: item.id, renderTax: true, name: item.charge, amount: this.formattedNumber(item.amount), tax: item.tax_model, tax_amount: item.amount, subtotal: 0 })
                            });
                        } else {
                            taxes.push({ ...initChargeItem })
                        }
                        var history_notes = null;
                        if (dt.history_notes) {
                            history_notes=dt.history_notes
                        }
                        this.data = {
                            count_print: dt.count_print,
                            po_id: this.id,
                            po_number: dt.code,
                            company_id: dt.company_id,
                            sources: dt.sources,
                            external_code: dt.external_code,
                            eta: dt.eta,
                            date: dt.date,
                            posting_date: dt.posting_date,
                            is_buying_price: dt.is_buying_price,
                            supplier: dt.supplier,
                            paymentTerms: dt.payment_term,
                            purchaseGroup: dt.purchase_group,
                            purchaseType: dt.purchase_type,
                            warehouses: [
                                {
                                    operating_unit_id: dt.operating_unit_id,
                                    operating_unit_code: dt.operating_unit_code,
                                    warehouse: dt.warehouse,
                                    currency: dt.currency,
                                    charge_type: dt.charge_type,
                                    prorate_method: dt.prorate_method,
                                    status: dt.status,
                                    po_virtual_code: dt.virtual_code,
                                    renderItems: true,
                                    notes: dt.notes,
                                    history_notes:history_notes,
                                    charge: {
                                        taxes: taxes,
                                        total_charges: dt.total_charge,
                                        total_taxes: dt.original_tax,
                                        grand_total: 0
                                    },
                                    cart: {
                                        items: items,
                                        total_price_list: dt.sub_total,
                                        total_discount: dt.discount,
                                        total_purchase: 0,
                                        total_tax: dt.tax,
                                        grand_total: 0,
                                        final_total: dt.total
                                    }
                                }]
                        }
                        this.refreshForm();
                        console.log(this.data)
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            type: "confirm",
                            color: "danger",
                            title: `Error`,
                            text: `${resp.code} : ${resp.message}. Do you want to refetch the data?`,
                            accept: this.initData,
                        });
                        console.log(resp)
                    }
                });

        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formattedNumber: () => {
            return (val) => {
                const formattedValue = parseInt(val).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });
    
                const valueWithoutCurrencySymbol = formattedValue.replace(/[Rp\s]/g, '');
                return valueWithoutCurrencySymbol;
            }
        },
        formatNumDec: () => {
            return (val) => {
                const parsedValue = parseFloat(val);
                const options = parsedValue % 1 === 0 ? {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                } : {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                };

                const formattedValue = parsedValue.toLocaleString("en-US", options);
                const valueWithoutCurrencySymbol = formattedValue.replace(/[^\d,.]/g, '');
                return valueWithoutCurrencySymbol;
            }
        },        
        cisDetail() {
            return this.isDetail;
        },
        isEdit() {
            return this.id != undefined;
        }        
    },
}
</script>

<style>
.po-card .vs-input--input.normal{
    text-align: right !important;
}

.customPrompt .vs-dialog footer{
    display: none;
}

.customPrompt .vs-dialog{
    min-width: 620px !important;
}

.vs-dialog-success .vs-dialog footer .vs-button-primary{
    display: none;
}
</style>